<template>

    <div>
        <div class="my-5 mb">
            <label  class="block text-sm font-medium leading-5 text-gray-100">Search a user</label>
            <div class=" mt-2">
                <!--                <label  class="sr-only">Search by username</label>-->

                <div v-if="selectedUser" class="flex">
                    <div class="group flex justify-between items-center px-4 py-2 focus:outline-none focus:bg-gray-100 focus:text-gray-900 bg-white rounded-md max-w-lg w-72 lg:max-w-xs mr-8">
                        <div class="flex justify-between items-center text-sm leading-5 text-gray-700">
                            <img :src="selectedUser.avatar_url"  class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500">
                            {{ selectedUser.username }}
                        </div>
                        <button @click="selectedUser = null">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#073d50"/>
                            </svg>
                        </button>
                    </div>
                    <button @click="addEducator(selectedUser)" type="button"
                            class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Add {{selectedUser.username }} as an educator
                    </button>

                </div>
                <div v-else class="relative w-72">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                    </div>

                    <input v-model="searchTerm" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-white placeholder-gray-400 focus:outline-none focus:bg-white sm:text-sm transition duration-150 ease-in-out" placeholder="Search by username">
                </div>

                <div class=" absolute w-72">
                    <userSelection :chosen="selectedUser" :users="userList" v-on:close-selection="selectionOpen = false"
                                   v-on:toggle-selection="selectionOpen = !selectionOpen"
                                   v-on:user-selected="userSelected"  :open="selectionOpen" class="z-50 w-full "/>
                </div>
            </div>
        </div>

        <div class="my-10 bg-white shadow overflow-hidden sm:rounded-md z-10 ">
            <ul>
                <li v-for="(collaborator, index) in users" :key="index" class="border-t border-gray-200">
                    <a href="#" class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 flex items-center">
                                <div class="flex-shrink-0">
                                    <img class="h-12 w-12 rounded-full" :src="collaborator.avatar_url" alt="" />
                                </div>
                                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                        <div class="text-lg leading-5 font-medium text-indigo-600 truncate">{{ collaborator.username }}</div>
                                        <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                                <path fill-rule="evenodd" d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" clip-rule="evenodd"/>
                                            </svg>
                                            <span class="truncate">{{ collaborator.email }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button @click="removePressed(collaborator.username, index)" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          <slot name="button">
              Remove
          </slot>
        </button>
      </span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <successNotification :open="showSuccessRemove">
                <template v-slot:header>
                    Removed!
                </template>
                <template v-slot:text>
                    User removed successfully.
                </template>
            </successNotification>

            <successNotification :open="showSuccessAdd">
                <template v-slot:header>
                    Added!
                </template>
                <template v-slot:text>
                    Educator added successfully.
                </template>
            </successNotification>

            <alert-modal :open="showAlertModal" v-on:close="closeAlertModal" v-on:reset="removeUser">
                <template v-slot:header>
                    User deletion requested!
                </template>
                <template v-slot:text>
                    You've requested to delete a user. Please confirm if you meant to take this action.
                </template>
                <template v-slot:button>
                    Delete
                </template>
            </alert-modal>


        </div>
    </div>


</template>

<script>
    import UserSelection from '../../components/UserSelection'
    import SuccessNotification from '../../components/ActionNotification'
    import AlertModal from '../../components/AlertModal'
    import * as axios from '../../axios-auth'
    export default {
        name: "AdminUsers",
        // props:['communityName', 'communityOwner'],
        components: {
            successNotification: SuccessNotification,
            userSelection: UserSelection,
            alertModal: AlertModal,
        },
        data() {
            return {
                page: 3,
                users: [],
                usernameToDelete: null,
                indexToDelete: null,
                showAlertModal: false,
                showSuccessRemove: false,
                showSuccessAdd: false,
                searchTerm: "",
                userList: [],
                selectionOpen: false,
                currentRequest: null,
                selectedUser: null,
                profileOpen: false,
            }
        },
        created(){
            // this.fetchUsers()
            this.fetchPaginatedUsers()
        },

        methods: {
            fetchPaginatedUsers(){
                axios.jigo.get(`/getUsers`, {params: {page: this.page}}).then((resp) => {
                    if (resp.status === 200) {
                        this.users = resp.data
                    }
                }).catch( (err) => {
                    console.log(err.response.data, err)
                })
            },
            fetchUsers(){
                const colReq = {
                    communityOwner: this.communityOwner,
                    communityName: this.communityName
                }
                axios.jigit.get(`/admin/users`, colReq).then((resp) => {
                    if (resp.status === 200) {
                        this.users = resp.data
                    }
                }).catch( (err) => {
                    console.log(err.response.data, err)
                })
                // axios.jigit.get(`/repos/${this.communityOwner}/${this.communityName}/collaborators`).then((resp) => {
                //     if (resp.status === 200) {
                //         this.educators = resp.data
                //     }
                // }).catch( (err) => console.log(err.response.data, err))
            },
            closeAlertModal(){
                this.showAlertModal = false
            },
            removePressed(username, index){
                this.showAlertModal = true
                this.usernameToDelete = username
                this.indexToDelete = index
            },
            removeUser(){
                const username = this.usernameToDelete
                const index = this.indexToDelete
                this.usernameToDelete = null
                this.indexToDelete = null
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });

                axios.jigo.post(`/deleteUser`, username, {headers: {"Content-Type": "text/plain"}}).then((resp) => {
                    if (resp.status === 204) {
                        this.users.splice(index, 1)
                        this.showSuccessRemove = true
                        loader.hide()
                        setTimeout(() => this.showSuccessRemove = false, 2000)
                    }
                }).catch( (err) => {
                    loader.hide()
                    console.log(err.response.data)
                })
            },
            userSelected(user){
                console.log("User selected: ", user)
                this.selectedUser = user
            },
            toggleSelect(){
                this.selectionOpen = !this.selectionOpen
            },
            addEducator(user){
                const permission = {
                    permission: "write"
                }
                axios.jigit.put(`/repos/${this.communityOwner}/${this.communityName}/collaborators/${user.username}`, permission).then((resp) => {
                    if (resp.status === 204) {
                        this.showSuccessAdd = true
                        setTimeout(() => this.showSuccessAdd = false, 2000)
                        this.fetchUsers()
                        this.selectedUser = null
                        this.searchTerm = ""
                    }
                }).catch( (err) => console.log(err.response.data))
            }
        },
        watch: {
            page(){
                this.fetchPaginatedUsers()
            },
            searchTerm(newTerm){
                clearTimeout(this.currentRequest)

                if(this.searchTerm.trim() !== ""){
                    this.currentRequest =  setTimeout(() => {
                        console.log("Searching!")
                        axios.jigit.get(`/users/search`, {params: {q: newTerm}}).then((resp) => {
                            if (resp.status === 200) {
                                this.selectionOpen = true
                                this.userList = resp.data.data
                                console.log("User List: ", this.userList)
                                // this.showSuccessRemove = true
                                // setTimeout(() => this.showSuccessRemove = false, 2000)
                            }
                        }).catch( (err) => console.log(err.response.data))

                    }, 500)
                } else {
                    this.selectionOpen = false
                }
            }
        }
    }
</script>

<style scoped>

</style>
